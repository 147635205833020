<div class="p-6 add-comment">
    {#if $store != null || data_holder == null}
    <h1 class="text-left heading">{(data_holder == null) ? "" : data_holder.actuality.title}</h1>
    <p>{ (data_holder == null) ? "" : data_holder.actuality.text}</p>
    <h2>Files</h2>
    
    {#if data_holder != null}
    {#if data_holder.actuality.files != null}

    {#each JSON.parse(data_holder.actuality.files) as item}
    <a href="{admin_url + "/" + item}">{item}</a><br>
    {/each}
    {/if}
    {/if}
    {/if}
</div>

<script>
    
    import { onMount } from 'svelte';
    import { store } from '../hooks/auth';
    
    let admin_url = "https://admin.katalyza.sk"
    $: data_holder = null;
    let lastSegment = "";
    
    onMount(async() => {
        let main = document.getElementById('main');
        main.style.display = "inline-block"; // Show the main content
        
        if (typeof window !== 'undefined') {
            lastSegment = location.href.substring(location.href.lastIndexOf("/") + 1);
        }
        
        let dataHolder = new FormData();
        dataHolder.append('id', lastSegment);
        
        fetch('https://admin.katalyza.sk/api/get/actualities/current', {
            method: "POST",
            body: dataHolder,
            headers: {
                // "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Access-Control-Allow-Origin": "https://katalyza.sk",
            }
        }).then(response => response.json())
        .then(function (data) {
            data_holder = data.data;
        }).catch(function (error) {
            console.log(error);
        });
    });
</script>