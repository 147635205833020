{#if success_app != null}
<div class="alert-badge success" on:click={ success_app = null }>
    {success_app}
</div>
{/if}

{#if error_app != null}
<div class="alert-badge danger" on:click={ error_app = null }>
    {error_app}
</div>
{/if}
<div class="p-6 add-comment">
    {#if $store != null || data_holder == null}
    <h1 class="text-left heading">{(data_holder == null) ? "" : data_holder.actuality.title}</h1>
    <p>{(data_holder == null) ? "" : data_holder.actuality.text}</p>
    <h2>Files</h2>
    {#if data_holder != null}
    <a href="{admin_url + "/" +  data_holder.actuality.avatar}">{data_holder.actuality.avatar.substring(20)}</a><br>
    {/if}
    
    {#if data_holder != null}
    {#if data_holder.actuality.files != null}
    {#each JSON.parse(data_holder.actuality.files) as item}
    <a href="{admin_url + "/" + item}">{item.substring(10)}</a><br>
    {/each}
    {/if}
    {/if}
    
    <hr class="hr-comments">
    {#if comments}
    {#each comments as item}
    <div class="grid grid-cols-12 card mb-3 pb-2">
        <div class="col-span-12 pt-6 pb-6">
            <p class="pl-6 pr-3">
                { item.comment.text }
            </p>
            {#if (item.user != null)}
            <p class="font-12 pl-6 pt-3">(added by { item.user.first_name } | { item.user.last_name })</p>
            {/if}
        </div>
    </div>
    {/each}
    {:else}
    {#if comment_real == null}
    <h2>No comments</h2>
    {/if}
    {/if}
    
    {#if comment_real}
    <div class="grid grid-cols-12 card mb-3 pb-2">
        <div class="col-span-12 pt-6 pb-6">
            <p class="pl-6 pr-3">
                { comment_real.comment.text }
            </p>
            {#if (comment_real.user != null)}
            <p class="font-12 pl-6 pt-3">(added by { comment_real.user.first_name } | { comment_real.user.last_name })</p>
            {/if}
        </div>
    </div>
    {/if}
    
    
    <hr class="hr-comments">
    <form on:submit|preventDefault={addComment} enctype="multipart/form-data">
        <div>
            <p class="label-input">Add comment</p>
            <textarea name="textarea" id="textarea" class="textarea-basic" bind:value={comment} required></textarea>
        </div>
        <div class="flex justify-between aling-bottom-pos">
            <input type="submit" value="Odoslať" class="button">
        </div>
    </form>
    {:else}
    <h2>Please log in: <NavLink to="/login" class="text-red-600">Login</NavLink></h2>
    {/if}
</div>
<script>
    
    import { onMount } from 'svelte';
    import { store } from '../hooks/auth';
    import NavLink from "../components/NavLink.svelte";
    
    let admin_url = "https://admin.katalyza.sk"
    
    let error_app = null;
    let success_app = null;
    
    $: data_holder = null;
    let comment = null;
    
    $: comments = null;
    $: comment_real = null;
    
    let lastSegment = "";
    
    function addComment() {
        let dataHolder = new FormData();
        console.log(lastSegment);
        dataHolder.append('private_id', lastSegment);
        dataHolder.append('text', comment);
        
        
        fetch('https://admin.katalyza.sk/api/add/private/comment', {
            method: "POST",
            body: dataHolder,
            headers: {
                // "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://katalyza.sk",
                
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                
                Authorization: "Bearer " + $store,
            }
        }).then(response => response.json())
        .then(function (data) {
            comment_real= data.data;
        }).catch(function (error) {
            error_app = "Please log in again"
        });
    }
    
    onMount(async() => {
        let main = document.getElementById('main');
        main.style.display = "inline-block"; // Show the main content
        
        if (typeof window !== 'undefined') {
            lastSegment = location.href.substring(location.href.lastIndexOf("/") + 1);
        }
        
        let error_app = "";
        let actualities = [];
        
        let admin_url = "https://admin.katalyza.sk/";
        let dataHolder = new FormData();
        dataHolder.append('id', lastSegment);
        
        fetch('https://admin.katalyza.sk/api/get/private/actualities/current', {
            method: "POST",
            body: dataHolder,
            headers: {
                // "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Access-Control-Allow-Origin": "https://katalyza.sk",
                
                Authorization: "Bearer " + $store,
            }
        }).then(response => response.json())
        .then(function (data) {
            data_holder = data.data;
            comments = data.data.comments;
        }).catch(function (error) {
            error_app = "Prihláste sa prosím znovu"
        });
    });
</script>