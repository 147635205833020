<div style="min-height: 80vh">
<div class="flex justify-center">
    <div class="login p-6">
        <h1 class="text-center">LOGIN</h1>
        <h2 class="text-center">Ste prihlásený ako používateľ</h2>
    </div>
</div>
</div>

<script>
    
    import { onMount } from 'svelte';
    
    onMount(() => {
        let main = document.getElementById('main');
        main.style.display = "none"; // Hide the main content
    });
</script>