<div class="p-6">
    {#each actualities as actuality}
    <a href="{actuality.actuality.website}" class="grid grid-cols-12 height-169 card cursor-pointer" target="_blank">
        <div href="{ admin_url + actuality.actuality.avatar}" target="_blank" class="col-span-3 image-holder" style="{ 'background:' + 'url( ' + admin_url  + actuality.actuality.avatar + ' )' }">
            &nbsp;
        </div>
        <div class="col-span-9 pt-6 pb-6">
            <p class="pl-6 pr-3">
                <b> { actuality.actuality.title }</b> <br />
                { actuality.actuality.text.length < 340 ? actuality.actuality.text : actuality.actuality.text.substring(0, 340) + "..." }
            </p>
            <p class="pl-6"><b>website: </b>{ actuality.actuality.website }</p>
            {#if (actuality.user != null)}
            <p class="font-12 pl-6 pt-3">(added by {actuality.user})</p>
            {/if}
        </div>
    </a>
    {/each}
</div>

<script>
    import { onMount } from 'svelte';
    import NavLink from "../components/NavLink.svelte";
    
    let actualities = [];
    let admin_url = "https://admin.katalyza.sk/";
    
    function redirectTo(url) {
        // if (!url.match(/^http?:\/\//i) || !url.match(/^https?:\/\//i)) {
        //     window.location.replace('http://' + url);
        //     return;
        // }
        window.location.replace(url);
    }
    
    onMount(async() => {
        let main = document.getElementById('main');
        main.style.display = "inline-block"; // Show the main content
        
        actualities = await fetch('https://admin.katalyza.sk/api/get/actualities').then(data => data.json())
        actualities = actualities.data;
    });
</script>