import { writable } from 'svelte/store';

let storeLocal = null;

if (typeof window !== 'undefined') {
    storeLocal = localStorage.getItem('store');
}

export const store = writable(storeLocal);


let sessions = []