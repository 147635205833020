<div class="p-6 addData">
    <h1 class="text-left heading">Add Private message</h1>
    {#if $store != null}
    <form on:submit|preventDefault={addActuality} enctype="multipart/form-data">
        <div>
            <p class="label-input">Title</p>
            <input type="text" name="name" id="name" class="input-basic" bind:value={title} required>
        </div>
        <div>
            <p class="label-input">Description</p>
            <textarea name="textarea" id="textarea" class="textarea-basic" bind:value={text} required></textarea>
        </div>
        <div>
            <p class="label-input">Title image</p>
            <input type="file" name="input_image" id="input_image" bind:value={input_image} required>
        </div>
        <div>
            <p class="label-input">Extra files</p>
            <input type="file" name="input_files" id="input_files" bind:value={input_files} multiple>
        </div>
        <div class="flex justify-between aling-bottom-pos">
            <input type="submit" value="Odoslať" class="button">
        </div>
        
        <div id="error_message" class="text-red-600 text-right">
            <small>{error_message}</small>
        </div>
    </form>
    {:else }
    
    <h2>Please log in: <NavLink to="/login" class="text-red-600">Login</NavLink></h2>
    {/if}
    
</div>

<script>
    import { onMount } from 'svelte';   
    import { store } from '../hooks/auth';
    import NavLink from "../components/NavLink.svelte";
    
    let title = null;
    let text = null;
    
    let input_image = null;
    let input_files = null;
    
    let error_message = "";
    
    function addActuality(){
        let image = document.getElementById('input_image');
        let files = document.getElementById('input_files');
        
        let dataHolder = new FormData();

        //Append all data to data holder and send it in one dataSet
        Array.from(files.files).forEach(element => {
            dataHolder.append('files[]', element);

        });

        dataHolder.append('titleImage', image.files[0]);
        dataHolder.append('title', title);
        dataHolder.append('text', text);

        if(image == null){
            error_message = 'Please select title image'
            return;
        }

        if ( title != '' || text != '' || input_image != null || input_files != null ) {
            fetch('https://admin.katalyza.sk/api/add/private', {
                method: "POST",
                body: dataHolder,
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    // "Content-Type": "application/json",

                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",   
                    "Access-Control-Allow-Origin": "https://katalyza.sk",

                    Authorization: "Bearer " + $store,
                }
            }).then(response => response.json())
            .then(function (data) {
                window.location = "/";
            }).catch(function (error) {
                error_message = 'Please select title image'
            });
            
            if ( error_message ) error_message = ''
        }
        else {
            error_message = 'Please fill all required inputs'
        }
    }
    
    onMount(async() => {
        let main = document.getElementById('main');
        main.style.display = "inline-block"; // Show the main content
    });
</script>