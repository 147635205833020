<div class="p-6">
    <h1 class="text-left heading">Contact</h1>
    <div class="grid grid-cols-12">
        <div class="col-span-12">
            <div class="grid grid-cols-12">
                <div class="col-span-3"><p>Chair</p></div>
                <div class="col-span-9">
                    <p>
                        { @html contact.text }
                    </p>
                </div>
            </div>
        </div>
        <div class="col-span-12">
            <div class="grid grid-cols-12 mt-5">
                <div class="col-span-3">Secretary</div>
                <div class="col-span-9">
                    <p>
                        { @html contact.secretary }
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="map border-top" style="background-image: url('{settings[0] ? admin_url + settings[0].avatar2 : './img/map.png'}')">
    &nbsp;
</div>

<script>
    
    import { onMount } from 'svelte';
    let contact = "";

	$: settings = [];
    let admin_url = "https://admin.katalyza.sk/";
    
    onMount(async () => {
        let main = document.getElementById('main');
        main.style.display = "inline-block"; // Show the main content
        
        await fetch('https://admin.katalyza.sk/api/get/web/contact', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Access-Control-Allow-Origin": "https://katalyza.sk",
            }
        }).then(response => response.json())
        .then(function (data) {
            contact = data.data;
        }).catch(function (error) {
            // error_app = "Error"
        });
        
        settings = await fetch('https://admin.katalyza.sk/api/get/settings').then(data => data.json())
        settings = settings.data;
    });
</script>