<div style="min-height: 80vh">
<div class="flex justify-center align-top">
    <div class="login p-6">
        <h1 clas="text-center">LOGIN</h1>
        <form on:submit|preventDefault={login}>
            <div>
                <p class="label-input">Name</p>
                <input type="text" name="name" id="name" class="input-basic" bind:value={email} required>
            </div>
            <div>
                <p class="label-input">Password</p>
                <input type="password" name="password" id="password" class="input-basic" bind:value={password} required>
            </div>
            <div id="error_message" class="text-red-600 text-right">
                <small>{error_message}</small>
            </div>
            <div class="flex justify-between aling-bottom-pos">
                <input type="submit" value="Login" class="button">
                <!-- <a href="" class="forgotten-pass">Forgotten password</a> -->
            </div>
        </form>
    </div>
</div>
</div>

<script>
    
    import { onMount } from 'svelte';
    import { store } from '../hooks/auth';
    
    let email = '';
    let password = '';
    let error_message = ''
    
    async function login() {
        if ( email != '' || password != '') {
            fetch('https://admin.katalyza.sk/api/login', {
                method: "POST",
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
                headers: {
                    "Access-Control-Allow-Origin": "https://katalyza.sk",
                    "Content-Type": "application/json",     
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                }
            }).then(response => response.json())
            .then(function (data) {
                $store = data.token;
                if (typeof window !== 'undefined') {
                    storeLocal = localStorage.setItem('store', $store);
                }
            }).catch(function (error) {
                error_message = 'Nesprávny email alebo heslo'
            });
            
            if ( error_message ) error_message = ''
        }
        else {
            error_message = 'Zadajte email alebo heslo'
        }
    }
    
    
    onMount(() => {
        let main = document.getElementById('main');
        main.style.display = "none"; // Hide the main content
    });
</script>