<div class="p-6">
    <h1 class="text-left heading">Member organizations</h1>
    
    {#each organizations as item}
    <a href="{item.website}" class="grid grid-cols-12 height-169 card cursor-pointer" target="_blank">
        <div class="col-span-2 image-holder" style="{ 'background:' + 'url( ' + admin_url  + item.avatar + ' )' }">
            &nbsp;
        </div>
        <div class="col-span-10 pt-6 pb-6">
            <p class="pl-6" style="font-size: 15px!important;">
                <b>{ @html item.base.first_name}</b> <br>
                { @html item.base.description.length  < 400 ? item.base.description : item.base.description.substring(0, 400) + "..."}
                <b>web:</b> { @html item.website.length < 50 ? item.website : item.website.substring(0, 50) + "..." }
                <br><b>Contact person:</b> { @html item.contact.length < 100 ? item.contact : item.contact.substring(0, 100) + "..."}
            </p>
        </div>
    </a>
    {/each}
</div>

<script>
    
    import { onMount } from 'svelte';
    let organizations = [];
    let admin_url = "https://admin.katalyza.sk/";
    
    function redirectTo(url) {
        // if (!url.match(/^http?:\/\//i) || !url.match(/^https?:\/\//i)) {
        //     window.location.replace('http://' + url);
        //     return;
        // }
        window.location(url);
    }
    
    onMount(async() => {
        let main = document.getElementById('main');
        main.style.display = "inline-block"; // Show the main content
        
        await fetch('https://admin.katalyza.sk/api/get/web/organizations', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "Access-Control-Allow-Origin": "https://katalyza.sk",
            }
        }).then(response => response.json())
        .then(function (data) {
            organizations = data.data;
            
        }).catch(function (error) {
            error_app = "Error"
        });
    });
</script>