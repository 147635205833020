<script>
	import { Router, Route } from "svelte-routing";
	import NavLink from "./components/NavLink.svelte";
	import Home from "./routes/Home.svelte";
	import About from "./routes/About.svelte";
	import Users from "./routes/Users.svelte";
	import Contact from "./routes/Contact.svelte";
	import Login from "./routes/Login.svelte";
	import AfterLogin from "./routes/AfterLogin.svelte";

	import Tutorial from "./routes/Tutorial.svelte";

	
	import AddActualities from "./routes/AddActualities.svelte";
	import AddPrivate from "./routes/AddPrivate.svelte";
	import PrivateChat from "./routes/PrivateChat.svelte";
	
	import PrivateMessage from "./routes/PrivateMessage.svelte";
	import ActualitiesMessage from "./routes/ActualitiesMessage.svelte";
	
	
	
	import Copyright from 'svelte-copyright';
	
	import { store } from './hooks/auth';
	
	// Used for SSR. A falsy value is ignored by the Router.
	export let url = "";
	
	function Logout(){
		$store = null;
		if (typeof window !== 'undefined') {
			storeLocal = localStorage.removeItem('store');
		}
	}
	
	// Messages 
	let success_app = null;
	let error_app = null;
	$: settings = [];
	let admin_url = "https://admin.katalyza.sk/";
	
	
	import { onMount } from 'svelte';
	onMount(async() => {
		settings = await fetch('https://admin.katalyza.sk/api/get/settings').then(data => data.json())
		settings = settings.data;
	});
	
</script>
{#if success_app != null}
<div class="alert-badge success" on:click={ success_app = null }>
	{success_app}
</div>
{/if}

{#if error_app != null}
<div class="alert-badge danger" on:click={ error_app = null }>
	{error_app}
</div>
{/if}

<div class="px-96">
	<Router url="{url}">
		<div class="div-set justify-end pr-5 border-left border-bottom border-right">
			{#if $store != null }
			<p class="logout" on:click={Logout}>Logout</p>
			{:else }
			<NavLink to="/login">Login</NavLink>
			{/if}
		</div>
		<header style="background-image: url('{settings[0] ? admin_url + settings[0].banner : './img/header.png'}')">
			<h1>
				{ settings[0] ? settings[0].name_banner : 'Catalytic workgroup at the Slovak Chemical Society' }
			</h1>
			<div class="black_background">&nbsp;</div>
		</header>
		<nav class="div-set pl-5 border-left border-bottom border-right">
			<NavLink to="/">Actualities</NavLink>
			<NavLink to="about">About us</NavLink>
			<NavLink to="users">Member organizations</NavLink>
			<NavLink to="contact">Contact</NavLink>
			{#if settings[0]}
				<a href="{admin_url +  settings[0].tutorial}">{ settings[0].manual }</a>
			{/if}
			


			
			{#if $store != null}
			<NavLink to="add/actualities">Add Actualities</NavLink>
			<NavLink to="add/private">Add Private</NavLink>
			<NavLink to="private/chat">Private chat</NavLink>
			{/if}
			
		</nav>
		<div class="separator">
			<img src="https://admin.katalyza.sk/images/products/img/line-separator.png " alt="Separator">
		</div>
		<div  style="">
			<div class="main-holder border-top border-right border-bottom border-left w-100" id="main" style="min-height: 80vh">
				<Route path="about" component="{About}" />
				<Route path="users" component="{Users}" />
				<Route path="/" component="{Home}" />
				<Route path="contact" component="{Contact}" />
				<!-- <Route path="manual" component="{Tutorial}" /> -->

				
				<Route path="add/actualities" component="{AddActualities}" />
				<Route path="add/private" component="{AddPrivate}" />
				<Route path="private/chat" component="{PrivateChat}" />
				
				<Route path="private/chat/:id" component="{PrivateMessage}" />
				<Route path="actualities/:id" component="{ActualitiesMessage}" />
			</div>
		</div>
		
		
		{#if $store != null }
			<Route path="login" component="{AfterLogin}" />
		{:else }
			<Route path="login" component="{Login}" />
		{/if}
		
		<div class="separator">
			<img src="https://admin.katalyza.sk/images/products/img/line-separator.png " alt="Separator">
		</div>
		<footer class="div-set border-top border-right border-left d-flex justify-center algn-center">
			<Copyright>
				Katalyza
			</Copyright>
		</footer>
	</Router>
	
</div>