<div class="p-6">
    {#if $store != null}
    {#each actualities as actuality}
    <div class="grid grid-cols-12 height-169 card">
        
        <a href="{ admin_url + actuality.avatar}" target="_blank" class="col-span-3 image-holder" style="{ 'background:' + 'url( ' + admin_url  + actuality.avatar + ' )' }">
            &nbsp;
        </a>
        <div class="col-span-9 pt-6 pb-6">
            <NavLink to="/private/chat/{actuality.secure_id}" class="text-red-600">
                
                <p class="pl-6 pr-3">
                    <b> { actuality.title }</b> <br />
                    { actuality.text < 340 ? actuality.text : actuality.text.substring(0, 340) + "..."}
                </p>
                <!-- <p class="font-12 pl-6 pt-3">(added by Horvath, FCHPT)</p> -->
                
            </NavLink>
        </div>
    </div>
    {/each}
    {:else}
    <h2>Please log in: <NavLink to="/login" class="text-red-600">Login</NavLink></h2>
    {/if}
</div>
<script>
    
    import { onMount } from 'svelte';
    import { store } from '../hooks/auth';
    import NavLink from "../components/NavLink.svelte";
    
    let error_app = "";
    let actualities = [];
    
    let admin_url = "https://admin.katalyza.sk/";
    
    onMount(async() => {
        let main = document.getElementById('main');
        main.style.display = "inline-block"; // Show the main content
        
        await fetch('https://admin.katalyza.sk/api/get/private/actualities', {
            method: "GET",
            headers: {
                "Access-Control-Allow-Origin": "https://katalyza.sk",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                Authorization: "Bearer " + $store,
            }
        }).then(response => response.json())
        .then(function (data) {
            actualities = data.data;
            
        }).catch(function (error) {
            error_app = "Prihláste sa prosím znovu"
        });
    });
</script>